/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PoseidonT3, PoseidonT3Interface } from "../PoseidonT3";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256[2]",
        name: "",
        type: "uint256[2]",
      },
    ],
    name: "poseidon",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

const _bytecode =
  "0x61011961003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060335760003560e01c806329a5f2f6146038575b600080fd5b60496043366004605b565b50600090565b60405190815260200160405180910390f35b600060408284031215606c57600080fd5b82601f830112607a57600080fd5b6040516040810181811067ffffffffffffffff8211171560aa57634e487b7160e01b600052604160045260246000fd5b806040525080604084018581111560c057600080fd5b845b8181101560d857803583526020928301920160c2565b50919594505050505056fea264697066735822122056469e201c237c094baa52f09d685475d78c42dc2c6f24c4589b6f02a70db71164736f6c634300080a0033";

type PoseidonT3ConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: PoseidonT3ConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class PoseidonT3__factory extends ContractFactory {
  constructor(...args: PoseidonT3ConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
    this.contractName = "PoseidonT3";
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<PoseidonT3> {
    return super.deploy(overrides || {}) as Promise<PoseidonT3>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): PoseidonT3 {
    return super.attach(address) as PoseidonT3;
  }
  connect(signer: Signer): PoseidonT3__factory {
    return super.connect(signer) as PoseidonT3__factory;
  }
  static readonly contractName: "PoseidonT3";
  public readonly contractName: "PoseidonT3";
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): PoseidonT3Interface {
    return new utils.Interface(_abi) as PoseidonT3Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PoseidonT3 {
    return new Contract(address, _abi, signerOrProvider) as PoseidonT3;
  }
}
