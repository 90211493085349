/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { SemaphoreCore, SemaphoreCoreInterface } from "../SemaphoreCore";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "nullifierHash",
        type: "uint256",
      },
    ],
    name: "NullifierHashAdded",
    type: "event",
  },
];

const _bytecode =
  "0x6080604052348015600f57600080fd5b50603f80601d6000396000f3fe6080604052600080fdfea2646970667358221220bf6dc927a1b02f72fed055682e950605ae48fe133dba00c3c406b00e74110ff164736f6c634300080a0033";

type SemaphoreCoreConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: SemaphoreCoreConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class SemaphoreCore__factory extends ContractFactory {
  constructor(...args: SemaphoreCoreConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
    this.contractName = "SemaphoreCore";
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<SemaphoreCore> {
    return super.deploy(overrides || {}) as Promise<SemaphoreCore>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): SemaphoreCore {
    return super.attach(address) as SemaphoreCore;
  }
  connect(signer: Signer): SemaphoreCore__factory {
    return super.connect(signer) as SemaphoreCore__factory;
  }
  static readonly contractName: "SemaphoreCore";
  public readonly contractName: "SemaphoreCore";
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): SemaphoreCoreInterface {
    return new utils.Interface(_abi) as SemaphoreCoreInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SemaphoreCore {
    return new Contract(address, _abi, signerOrProvider) as SemaphoreCore;
  }
}
